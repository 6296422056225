let map;

window.addEventListener('turbo:load', (e) => {
  if (document.getElementById('map')) {
    initMap();
  }
})

document.addEventListener("turbo:before-cache", function() {
  if (document.getElementById('map') && map) {
    map.destroy();
  }
})

async function initMap() {
    await ymaps3.ready;
    const {YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker, YMapControls} = ymaps3;

    const {YMapZoomControl} = await ymaps3.import('@yandex/ymaps3-controls@0.0.1');

    map = new YMap(
      document.getElementById('map'),
      {
        location: {
          center: [60.692648572772875, 56.75001004924172],
          zoom: window.innerWidth < 600 ? 10 : 13
        },
        behaviors: ['drag', 'pinchZoom', 'dblClick'],
      },
      [
        new YMapDefaultSchemeLayer({}),
        new YMapDefaultFeaturesLayer({id: 'features'})
      ]
    );

    if (window.CLINICS_COORDS) {
      JSON.parse(window.CLINICS_COORDS).forEach((el) => {
        const markerElement = document.createElement('img');
        markerElement.className = 'icon-marker';
        markerElement.src = window.PIN_IMG;
        map.addChild(new YMapMarker({coordinates: [el.longitude, el.latitude]}, markerElement));
      })
    }

    map.addChild(new YMapControls({position: 'right'}).addChild(new YMapZoomControl({})));
}
